.pill-button {
  padding: 10px 30px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  background-color: #5e49ec;
  border: none;
  border-radius: 50px; /* Gives the pill shape */
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  font-family: "poppins", sans-serif;

  user-select: none;
  min-width: 100px;
}

.pill-button:hover {
  background-color: #8676f1; /* Slightly lighter black when hovered */
  color: white;
}

.pill-button:active,
.pill-button.pressed {
  box-shadow: 0 2px 3px white; /* Smaller shadow for pressed effect */
  transform: translateY(2px); /* Simulates the button being pressed down */
}
/* @keyframes wiggle {
  0%,
  100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
} */

/* .pill-button.wiggle {
  animation: wiggle 0.5s ease-in-out infinite;
} */

@keyframes wiggleAndPulse {
  0%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1); /* Increased scale when the button is not rotated */
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9); /* Decreased scale for variation, optional */
  }
}

.pill-button.wiggle {
  animation: wiggleAndPulse 1s ease-in-out infinite;
}
