.foldercontainer {
  display: flex;
  background-color: rgba(255, 255, 255, 0.789);
  width: 130px;
  height: 130px;
  justify-content: center;
  border-radius: 10px;
  position: relative;
  top: -10px;
}

/* .previewcontainer {
  background-color: rgba(255, 255, 255, 0.789);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
} */
.previewtile {
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.789);
  position: relative;
  top: 10px;
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}
.modal-content {
  display: grid;
  margin-left: 20px;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  padding: 10px;
  width: 100%;
  height: 100%;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.601);
  border-radius: 20px;
  z-index: 1000;
  padding: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.tile {
  height: 100px;
  width: 100px;
  border-radius: 20px;
}
