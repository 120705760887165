.outersharecontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.shareContainer {
  background-color: #1a1a2e;
  border-radius: 10%;
  margin-top: 0px;
  width: 100%;
  max-width: 120%;
  height: 180px;
  font-size: 12px;
  border-radius: 10px;
  padding-top: 5px;
  padding-inline: 5px;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.share-indicator {
  position: relative;
  top: 20px;
  left: 0px;
  width: auto;
  background-color: #1a1a2e;
  color: #fff;
  padding: 8px 20px;
  /* border: 1px solid white; */
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  font-family: "poppins", sans-serif;
}
.small-container {
  /* border: 1px solid white; */
  width: 35%;
  min-width: 100px;
  height: 80%;
  margin: 5px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.icons {
  height: 30px;
}
.stats-number {
  color: #f6d252;
  font-size: 20px;
}
