.pill-button-two {
  padding: 10px 30px;
  font-size: 16px;
  color: #5e49ec;
  font-weight: bold;
  background-color: #1a1a2e;
  border: none;
  border-radius: 50px; /* Gives the pill shape */
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  font-family: "poppins", sans-serif;
  user-select: none;
  border: 2px solid #5e49ec;

  min-width: 100px;
}

.pill-button-two:hover {
  background-color: #303042; /* Slightly lighter black when hovered */
}

.pill-button-two:active,
.pill-button-two.pressed {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0); /* Smaller shadow for pressed effect */
  transform: translateY(2px); /* Simulates the button being pressed down */
}
