.feedbackcontainer h3 {
  color: #f6d252;
  cursor: pointer;
}

.rotate-90 {
  transform: rotate(90deg);
  height: 80px;
  width: 80px;
  position: relative;
  top: 35px;
}
