@font-face {
  font-family: "Aldrich";
  src: local("Aldrich"), url("./fonts/Aldrich-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/Poppins-Medium.ttf") format("truetype");
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevents scrolling on the entire page */
  margin: 0;
  padding: 0;
  background-color: #1a1a2e;
  color: white;
  font-family: "Aldrich", sans-serif;
  overflow-y: auto;
}

.App {
  text-align: center;
  width: 100%; /* Use 100% for full width or a max-width in percentage */
  max-width: 375px;
  max-height: 667px;
  margin: 0 auto; /* Centers the app horizontally */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1a1a2e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: darkblue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.round-button {
  width: 100px; /* Make the width and height equal */
  height: 100px; /* Make the width and height equal */
  border-radius: 50%; /* Make it round */
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-button:hover {
  background-color: #0056b3;
}
