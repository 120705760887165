.custom-input-container {
  display: flex;
  align-items: center;
  gap: 0.2em;
  cursor: text;
  justify-content: flex-start;
}

.custom-input-container .char,
.custom-input-container .cursor {
  display: flex;
  /* padding-bottom: 5px; */
  border-bottom: 1px solid black; /* Underline */
  background-color: #8c8c96;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  margin-bottom: 25px;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.cursor {
  border-bottom: 5px solid rgb(220, 56, 19); /* Make the cursor more prominent */
  animation: blink 1s steps(2, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

.hidden-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 0;
  width: 0;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.shake {
  animation: shake 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.user-input {
  flex-grow: 1;
  border: none;
  outline: none;
}
