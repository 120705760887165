.resultscontainer {
}

.topDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  background-color: "#1a1a2e";
  z-index: 1000;
}

.middleDiv {
  background-color: "#1a1a2e";
  z-index: 0;
  height: 25%;
}
.bottomDiv {
  /* border-top: lightgrey 1px solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: "#1a1a2e";
  height: 25%;
  max-height: 100px;
}
.buttonsRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  top: 5px;
  height: 75px;
}
/* .borderLine {
  position: relative;
  top: 40px;
  border-top: lightgrey 1px solid;
} */
