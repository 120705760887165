.carouselcontainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 150%;
}

.carousel-item {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background: #484858;
  width: 250px;
  height: 200px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.new-indicator {
  position: absolute;
  top: -10px;
  left: -20px;
  width: auto;
  background-color: #4291f6;
  color: #fff;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  font-family: "poppins", sans-serif;
}
