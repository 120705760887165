.container {
  margin-top: 50px;
  flex: auto;
  flex-direction: column;
}
.buttons {
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: "50%";
  min-width: 100px;
  min-height: 150px; /* Adjust as needed */
}
